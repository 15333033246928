import Button from 'components/Button';
import { ErrorMessage, Field, Form } from 'formik';
import { SectionForm, SectionFormValues } from '../SectionForm';
import { AbsoluteErrorMessage } from 'components/AbsoluteErrorMessage';
import { nameLabel } from 'services/labels';
import WebComponent from 'utils/web-component';

interface SectionsNewSectionFormProps {
  entityName: string;
  onSubmit: (payload: SectionFormValues) => void;
}

const initialValues: SectionFormValues = {
  name: '',
};

export const SectionsNewSectionForm = ({ entityName, onSubmit }: SectionsNewSectionFormProps) => {
  return (
    <SectionForm initialValues={initialValues} onSubmit={onSubmit}>
      {({ isSubmitting, isValid, dirty }) => (
        <Form>
          <div className="mb-6 flex items-end rounded bg-slate-50 p-5 md:items-center md:px-32">
            <div className="mr-3 flex flex-grow flex-col items-start md:flex-row md:items-center">
              <span className="mb-1 flex-shrink-0 text-sm font-semibold md:mb-0">Create a new {entityName}</span>
              <div className="relative ml-0 flex-grow md:ml-4">
                <Field
                  className="!h-9 !text-sm"
                  aria-label={nameLabel(entityName)}
                  placeholder={nameLabel(entityName)}
                  name="name"
                  type="text"
                  autoComplete="off"
                />
                <ErrorMessage name="name" className="pt-0 text-xxs" component={AbsoluteErrorMessage} />
              </div>
            </div>
            <WebComponent tag="fl-button" size="small" type="submit" disabled={isSubmitting || !isValid || !dirty}>
              Create
            </WebComponent>
          </div>
        </Form>
      )}
    </SectionForm>
  );
};
