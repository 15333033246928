import { pathToAutomatedFeeds } from 'services/routes';
import { Link } from 'react-router-dom';
import { useEffect } from "react";

import Page from 'components/Page';
import CardContentByChannel from './components/CardContentByChannel';
import CardGettingStarted from './components/CardGettingStarted';
import CardLayoutViews from './components/CardLayoutViews';
import CardPostStats from './components/CardPostStats';
import CardProductNews from './components/CardProductNews';
import Warning, { WarningCtaLabel } from './components/Warning';

import styles from './Dashboard.module.scss';
import { withSiteLoaded } from 'components/WithSiteLoaded';
import useCurrentSite from 'hooks/useCurrentSite';
import { useUserProfile } from 'hooks/useUserProfile';
import useSWR from 'swr';
import { apiClient, PATHS, fetchCampaignEntries, fetchCampaigns } from 'services/api';
import { format, sub } from 'date-fns';
import CardSocialAnalytics from './components/CardSocialAnalytics';
import { processEntry } from './components/CardSocialAnalytics/utils/process';
import LoadingIndicator from 'components/Loader/LoadingIndicator';
import classNames from 'classnames';
import { getSiteSettings } from 'concepts/site';
import { useAppSelector } from 'hooks/useAppSelector';
import CardReferAFriend from './components/CardReferAFriend';
import { CardLoader } from './components/CardLoader';
import config from 'config';
import { postHogIdentifyUser } from "../../utils/post_hog_sync";

interface siteUser {
  [key: string]: any;
}

const socialAnalyticsGetter = async (siteId: SiteId): Promise<CampaignEntryWithVideo> => {
  const {
    data: { campaigns },
  } = await fetchCampaigns(siteId);

  const campaignWithAllContent = campaigns.find((campaign) => campaign.flockler_section_id === null);

  if (!campaignWithAllContent) {
    return Promise.reject('No campaign with all content found');
  }

  const { data } = await fetchCampaignEntries(siteId, campaignWithAllContent.id, {
    count: 1,
    start_date: format(sub(new Date(), { days: 6 }), "yyyy-MM-dd'T00:00:00.000'"),
    sort: 'score',
  });

  if (!data.entries.length) {
    return Promise.reject('No posts found');
  }

  return processEntry(data.entries[0]);
};

const Dashboard = () => {
  const site = useCurrentSite();
  const settings = useAppSelector(getSiteSettings);
  const user = useUserProfile() as siteUser;

  const { data: dashboardData, error: dashboardError } = useSWR<DashboardResponse>(
    site ? PATHS.DASHBOARD(site.id) : null,
    apiClient.get
  );

  const { data: metricsData, error: metricsError } = useSWR<MetricsEmbedsResponse>(
    site ? PATHS.METRICS_EMBEDS(site.id, { order: 'loadInitial', count: 4 }) : null,
    apiClient.get
  );

  const shouldFetchSiteMetrics = !metricsData?.embeds?.length && !metricsError;

  const { data: siteMetricsData, error: siteMetricsError } = useSWR<MetricsSitesResponse>(
    shouldFetchSiteMetrics
      ? PATHS.METRICS_SITES(site.id, {
          start_date: format(sub(new Date(), { days: 6 }), "yyyy-MM-dd'T00:00:00.000'"),
          time_bucket: 'P500D',
        })
      : null,
    apiClient.get
  );

  const embeds = metricsData?.embeds.filter((embed) => embed?.requests?.loadInitial) ?? [];

  const { data: campaignEntry } = useSWR<CampaignEntryWithVideo>(
    site ? [site.id, 'campaigns'] : null,
    socialAnalyticsGetter
  );

  const isLoading = !dashboardData && !dashboardError && !metricsData && !metricsError;

  useEffect(() => {
    if (user && !isLoading) {
      postHogIdentifyUser(user.id)
        .then(r => console.log(r));
    }
  }, [user, isLoading]);

  return (
    <div className={styles.headerBg}>
      <Page>
        {user && !user.confirmed_at && (
          <Warning label="Attention" severity="warning">
            Please confirm your email address by clicking the link in your inbox.{' '}
            <a href={`https://${config.flocklerBaseDomain}/users/confirmation/new`} rel="noreferrer" target="_blank">
              Resend my confirmation link
            </a>
            .
          </Warning>
        )}

        {!!dashboardData?.alerts?.length && (
          <div>
            {dashboardData?.alerts.map((alert) => (
              <Warning key={alert.message} label="Attention" severity={alert.severity}>
                {alert.message}
                {alert.notification_type === 'broken_feeds' ? (
                  <Link
                    to={pathToAutomatedFeeds(site.site_url)}
                    className="group ml-1 space-x-1 text-inherit focus-within:no-underline hover:no-underline"
                  >
                    <WarningCtaLabel>{alert.cta_text}</WarningCtaLabel>
                  </Link>
                ) : (
                  <a
                    href={alert.cta_link ?? ''}
                    className="group ml-1 space-x-1 text-inherit focus-within:no-underline hover:no-underline"
                  >
                    <WarningCtaLabel>{alert.cta_text}</WarningCtaLabel>
                  </a>
                )}
              </Warning>
            ))}
          </div>
        )}

        {dashboardData && (
          <div data-testid="dashboard-content" className="grid animate-fade-in grid-cols-6 gap-6 opacity-0">
            <CardPostStats
              site={site}
              className="col-span-6 sm:col-span-3 lg:col-span-2"
              posts={dashboardData.postCounts.published}
              inbox={dashboardData.postCounts.inbox}
            />

            <CardContentByChannel
              site={site}
              postCounts={dashboardData.postCounts}
              className="col-span-6 sm:col-span-3 lg:col-span-2"
            />

            {!settings ? (
              <CardLoader className="col-span-6 sm:col-span-3 lg:col-span-2" />
            ) : settings.referral_program_enabled ? (
              <CardReferAFriend site={site} className="col-span-6 sm:col-span-3 lg:col-span-2" />
            ) : (
              <CardGettingStarted
                site={site}
                completedTasks={dashboardData.completedTasks}
                className="col-span-6 sm:col-span-3 lg:col-span-2"
              />
            )}

            {(!metricsData && !metricsError) || (shouldFetchSiteMetrics && !siteMetricsData && !siteMetricsError) ? (
              <CardLoader className="col-span-6 sm:col-span-3" />
            ) : (
              <CardLayoutViews
                site={site}
                embeds={embeds}
                siteMetrics={siteMetricsData}
                className="col-span-6 sm:col-span-3"
              />
            )}

            {dashboardData.productNews.length > 0 && (
              <CardProductNews articles={dashboardData.productNews} className="col-span-6 sm:col-span-3" />
            )}

            {campaignEntry && (
              <CardSocialAnalytics
                className={classNames('col-span-6', !campaignEntry.image_url && 'sm:col-span-3')}
                entry={campaignEntry}
              />
            )}
          </div>
        )}

        {isLoading && (
          <div className="flex justify-center py-10">
            <LoadingIndicator />
          </div>
        )}
      </Page>
    </div>
  );
};

export default withSiteLoaded(Dashboard);
