import React, { useRef, useEffect, ReactNode } from 'react';

interface WebComponentProps {
  tag: string;
  children?: ReactNode;
  [key: string]: any;
}

const WebComponent: React.FC<WebComponentProps> = ({ tag, children, ...props }) => {
  const elementRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const element = elementRef.current;

    if (element) {
      Object.keys(props).forEach((key) => {
        if (key.startsWith('on') && typeof props[key] === 'function') {
          const eventName = key.slice(2).toLowerCase();
          element.addEventListener(eventName, props[key]);
        } else if (key !== 'children') {
          element.setAttribute(key, props[key]);
        }
      });

      return () => {
        Object.keys(props).forEach((key) => {
          if (key.startsWith('on') && typeof props[key] === 'function') {
            const eventName = key.slice(2).toLowerCase();
            element.removeEventListener(eventName, props[key]);
          }
        });
      };
    }
  }, [props]);

  return React.createElement(tag, { ref: elementRef, ...props }, children);
};

export default WebComponent;
